import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import {GridComponentContainer, H2, TextContainer} from '../components/atoms';
import {DynamicZone, StoryHeader, SEO} from '../components/organisms';
import NewsItemPreview from '../components/organisms/blocks/NewsItemPreview';
import {PageContent} from '../components/organisms/Layout';
import {colors, devices} from '../styles/theme';

const RelatedContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${devices.desktop} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

const RelatedHeading = styled(TextContainer)`
  grid-column: span 8;
  color: ${colors.blue};
`;

const NewsItem = ({data}) => {

  const {strapi: {
    newsItem: {
      tags,
      image,
      createdAt,
      title,
      content,
      relatedNews,
      SEO: _SEO
    },
  }} = data;
  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${image?.file?.publicURL}`
          }
        ]}
      />
      <StoryHeader
        tags={tags}
        storyImage={image}
        createdAt={createdAt}
        title={title}
      />
      <DynamicZone blocks={content}/>
      {relatedNews.length > 0 && (
        <RelatedContainer>
          <RelatedHeading>
            <H2>Related news</H2>
          </RelatedHeading>
          {relatedNews.map(related => (
            <NewsItemPreview key={related.id} newsItem={related}/>
          ))}
        </RelatedContainer>
      )}
    </PageContent>
  );
};

export default NewsItem;

export const NewsItemQuery = graphql`
  query GET_NEWS_ITEM($id: ID!) {
    strapi {
      newsItem(id: $id) {
        SEO {
          ...SEO
        }
        ...NewsItem
        ...RelatedNews
        content {
          __typename
          ...Carousel
          ...ContactUs
          ...ContactPersonBlock
          ...MediaWithText
          ...MediaBlock
          ...PlainText
          ...Share
          ...Testimonial
          ...USPBlock
          ...Download
        }
      }
    }
  }
`;
