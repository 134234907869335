import React from 'react';
import styled, {css} from 'styled-components';
import {colors, devices} from '../../../styles/theme';
import {Button, H6, Subtitle, TextContainer, Link} from '../../atoms';
import StoryLabel from '../../molecules/StoryLabel';
import Img from 'gatsby-image';
import {parseDate} from '../../../utils/time';

const Container = styled.div`
  grid-column: span 2;
  margin: 24px 0;
  @media ${devices.tabletLandscape} {
    margin: 40px 0;
  }
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const thumbnailStyle = css`
  height: 224px;
`;

const ThumbNail = styled(Img)`${thumbnailStyle}`;
const NativeThumbnail = styled.img`
  ${thumbnailStyle}
  margin: 0;
`;

const NewsInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin: 16px;
`;

const Time = styled(Subtitle)`
  font-weight: bold;
  color: ${colors.lightBlue};
`;

const BlogTitle = styled(H6)`
  color: ${colors.blue};
`;

const NewsItemPreview = ({newsItem}) => {
  const {
    tags,
    image,
    createdAt,
    title,
    slug,
  } = newsItem;

  return (
    <Container>
      <LabelsContainer>
        {tags.map(({tag, color, customHexColor}) => (
          <StoryLabel key={tag} color={customHexColor || colors[color]}>
            {tag}
          </StoryLabel>
        ))}
      </LabelsContainer>
      <TextContainer>
        <Link to={`/news${slug}`}>
          {image?.file
            ? image?.file?.extension === 'svg'
              ? <NativeThumbnail
                src={image?.file?.publicURL}
                alt={image?.alternativeText}
              />
              : <ThumbNail
                fluid={image?.file?.childImageSharp?.fluid}
                alt={image?.alternativeText}
              />
            : null
          }
        </Link>
        <NewsInfo>
          <Time>{parseDate(createdAt)}</Time>
          <Link to={`/news${slug}`} style={{textDecoration: 'none'}}>
            <BlogTitle>{title}</BlogTitle>
          </Link>
          <Link to={`/news${slug}`}>
            <Button type="secondary">
              Read more
            </Button>
          </Link>
        </NewsInfo>
      </TextContainer>
    </Container>
  );
};

export default NewsItemPreview;
